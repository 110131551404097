<template lang="pug">
div.layout
  GoUpArrow
  MainNavbar
  div
    slot
</template>

<script setup>
import { useSettingsStore } from '~/stores/settings'

const settingsStore = useSettingsStore()
await settingsStore.getLangTree()

onMounted(() => {
  const documentHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
  }
  // window.addEventListener('resize', documentHeight)
  documentHeight()
})
</script>


<style lang='scss' scoped>
.layout {
  --bcg-color: var(--dark-color);
  --text-color: var(--light-color);
  --bcg-menu-color: var(--dark-color);

  background: var(--bcg-color);
}
</style>

